<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a href="https://stifta.se" class="navbar-item">
          <img src="@/assets/stifta.svg">
        </a>

        <ActAsUserPicker v-if="isLoggedIn && $store.getters.session.account.adminUser" class="" />
        <a role="button" class="navbar-burger burger" v-on:click="showNav = !showNav" v-bind:class="{ 'is-active' : showNav, 'navbar-no-margin-left' : beneficiaries && beneficiaries.length > 0 }" aria-label="menu" v-bind:aria-expanded="showNav ? 'true' : 'false'" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="main-navbar" class="navbar-menu" v-bind:class="{ 'is-active' : showNav }">
        <div class="navbar-end">
          <!-- <router-link v-if="isLoggedIn && $store.state.profile.beneficiary" class="navbar-item" active-class="active" to="/">Mina Sidor</router-link>
          <router-link v-if="isLoggedIn && $store.state.profile.delegate" class="navbar-item" active-class="active" to="/pensionables">Pensionsberättigade</router-link> -->
          <!--
          <router-link v-if="isLoggedIn" class="navbar-item" active-class="active" to="/sparande">Ditt sparande</router-link>
          <router-link v-if="isLoggedIn" class="navbar-item" active-class="active" to="/blanketter">Mallar och blanketter</router-link>
          <router-link v-if="isLoggedIn" class="navbar-item" active-class="active" to="/konto">Mina uppgifter</router-link>
          -->
          <a class="navbar-item" v-on:click="logout" v-if="isLoggedIn"><font-awesome-icon :icon="['far', 'user-circle']" /> Logga ut</a>
          <div class="navbar-item" v-if="!isLoggedIn">
            <router-link class="button is-primary" to="/login">Logga in</router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ActAsUserPicker from '@/components/ActAsUserPicker'

export default {
  name: 'app-header',
  components: {
    ActAsUserPicker
  },
  data () {
    return {
      showNav: false
    }
  },
  methods: {
    logout () {
      this.showNav = false
      this.$store.dispatch('logout')
        .then(() => this.$router.go('/logout'))
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    beneficiaries () {
      return []
      // return this.$store.state.profile.beneficiaries
    }
  },
  watch: {
    $route (to, from) {
      this.showNav = false
    }
  }
}
</script>

<style scoped lang="scss">
  .navbar .navbar-brand {
      padding-top: 38px;
      padding-bottom: 38px;
      flex-grow: 1000;
  }
  .navbar-item {
    color: #000000;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.03rem;
  }
  .navbar-brand img {
    max-height: 36px;
  }
  .navbar-no-margin-left {
    margin-left: 0
  }
  .active {
    color: #BBBCBD;
  }
</style>
