import Vue from 'vue'
import Vuex from 'vuex'
import session from '@/store/session'
import election from '@/store/election'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    election
  }
})
