<template>
  <footer class="footer">
    <div class="container">
      <div class="content">
        <p>
        Stifta AB | Box 409 | 651 09 Karlstad | 010-490 10 10 | <a href="mailto:info@stifta.se">info@stifta.se</a> | <a href="https://www.stifta.se">www.stifta.se</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>

<style scoped lang="scss">

</style>
