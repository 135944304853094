import ElectionAPI from '@/api/ElectionAPI'

export default {
  state: {
    elections: [],
    apiClient: null,
    error: false
  },
  mutations: {
    setElections (state, elections) {
      state.elections = elections
    },
    setApiClient (state, profileApi) {
      state.apiClient = profileApi
    },
    setError (state, error) {
      state.error = error
    },
    reset (state) {
      state.apiClient = null
    }
  },
  actions: {
    fetchElectionData ({ commit, dispatch }) {
      const electionApi = new ElectionAPI(this.getters.accessToken)
      commit('setApiClient', electionApi)

      return electionApi.getElections()
        .then(res => {
          commit('setElections', res.data)
        }).catch(err => {
          commit('setError', err)

          if (err.response.status === 401 || err.response.status === 403) {
            dispatch('logout')
          }
        })
    },
    clearElectionData ({ commit }) {
      commit('reset')
      return Promise.resolve(true)
    },
    logout ({ commit, dispatch }) {
      dispatch('clearElectionData')
      return Promise.resolve(true)
    }
  }
}
