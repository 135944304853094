import { render, staticRenderFns } from "./ActAsUserPicker.vue?vue&type=template&id=4889152b&scoped=true&"
import script from "./ActAsUserPicker.vue?vue&type=script&lang=js&"
export * from "./ActAsUserPicker.vue?vue&type=script&lang=js&"
import style0 from "./ActAsUserPicker.vue?vue&type=style&index=0&id=4889152b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4889152b",
  null
  
)

export default component.exports