<template>
  <div id="app">
    <div class="container outdated" v-if="isOldBrowser">
      <div>
        <h6>Din webbläsare stöds inte!</h6>
        <p>Uppdatera din webbläsare så att den här webbplatsen visas korrekt. <a href="https://www.google.com/chrome/">Uppdatera</a></p>
      </div>
    </div>
    <appHeader v-if="$route.path !== '/login'" />
    <router-view/>
    <appFooter v-if="$route.path !== '/login'" />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'app',
  components: { AppHeader, AppFooter },
  created () {
    this.updateElectionData()
  },
  data () {
    return {
      isOldBrowser: !!window.MSInputMethodContext && !!document.documentMode
    }
  },
  watch: {
    '$store.getters.isLoggedIn' () {
      this.updateElectionData()
    }
  },
  methods: {
    updateElectionData () {
      if (this.$store.getters.isLoggedIn) {
        this.$store.dispatch('fetchElectionData')
      } else {
        this.$store.dispatch('clearElectionData')
      }
    }
  }
}
</script>

<style lang="scss">
@import url("//hello.myfonts.net/count/390b0b");
@font-face {
  font-family: 'Nexa';
  font-weight: 700;
  src: url('~@/assets/nexa/390B0B_0_0.eot');
  src:
    url('~@/assets/nexa/390B0B_0_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_0_0.woff2') format('woff2'),
    url('~@/assets/nexa/390B0B_0_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Nexa';
  font-weight: 400;
  src: url('~@/assets/nexa/390B0B_1_0.eot');
  src:
    url('~@/assets/nexa/390B0B_1_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_1_0.woff2') format('woff2'),
    url('~@/assets/nexa/390B0B_1_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_1_0.ttf') format('truetype');
}

/* CircularStd @font-face kit */
@font-face {
  font-family: "CircularStd";
  src: url("~@/assets/fonts/CircularStd-Black.eot");
  src: url("~@/assets/fonts/CircularStd-Black.eot?#iefix") format("embedded-opentype"), url("~@/assets/fonts/CircularStd-Black.woff") format("woff"), url("~@/assets/fonts/CircularStd-Black.ttf") format("truetype"), url("~@/assets/fonts/CircularStd-Black.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("~@/assets/fonts/CircularStd-BlackItalic.eot");
  src: url("~@/assets/fonts/CircularStd-BlackItalic.eot?#iefix") format("embedded-opentype"), url("~@/assets/fonts/CircularStd-BlackItalic.woff") format("woff"), url("~@/assets/fonts/CircularStd-BlackItalic.ttf") format("truetype"), url("~@/assets/fonts/CircularStd-BlackItalic.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("~@/assets/fonts/CircularStd-Bold.eot");
  src: url("~@/assets/fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"), url("~@/assets/fonts/CircularStd-Bold.woff") format("woff"), url("~@/assets/fonts/CircularStd-Bold.ttf") format("truetype"), url("~@/assets/fonts/CircularStd-Bold.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("~@/assets/fonts/CircularStd-BoldItalic.eot");
  src: url("~@/assets/fonts/CircularStd-BoldItalic.eot?#iefix") format("embedded-opentype"), url("~@/assets/fonts/CircularStd-BoldItalic.woff") format("woff"), url("~@/assets/fonts/CircularStd-BoldItalic.ttf") format("truetype"), url("~@/assets/fonts/CircularStd-BoldItalic.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("~@/assets/fonts/CircularStd-Medium.eot");
  src: url("~@/assets/fonts/CircularStd-Medium.eot?#iefix") format("embedded-opentype"), url("~@/assets/fonts/CircularStd-Medium.woff") format("woff"), url("~@/assets/fonts/CircularStd-Medium.ttf") format("truetype"), url("~@/assets/fonts/CircularStd-Medium.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("~@/assets/fonts/CircularStd-MediumItalic.eot");
  src: url("~@/assets/fonts/CircularStd-MediumItalic.eot?#iefix") format("embedded-opentype"), url("~@/assets/fonts/CircularStd-MediumItalic.woff") format("woff"), url("~@/assets/fonts/CircularStd-MediumItalic.ttf") format("truetype"), url("~@/assets/fonts/CircularStd-MediumItalic.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("~@/assets/fonts/CircularStd-Book.eot");
  src: url("~@/assets/fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"), url("~@/assets/fonts/CircularStd-Book.woff") format("woff"), url("~@/assets/fonts/CircularStd-Book.ttf") format("truetype"), url("~@/assets/fonts/CircularStd-Book.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd";
  src: url("~@/assets/fonts/CircularStd-BookItalic.eot");
  src: url("~@/assets/fonts/CircularStd-BookItalic.eot?#iefix") format("embedded-opentype"), url("~@/assets/fonts/CircularStd-BookItalic.woff") format("woff"), url("~@/assets/fonts/CircularStd-BookItalic.ttf") format("truetype"), url("~@/assets/fonts/CircularStd-BookItalic.svg#bcc26993292869431e54c666aafa8fcd") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "DroidSansMonoDotted";
  src: url("~@/assets/fonts/DroidSansMonoDotted.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DroidSansMonoSlashed";
  src: url("~@/assets/fonts/DroidSansMonoSlashed.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/base/_all";
@import "~bulma/sass/elements/button";
@import "~bulma/sass/elements/container";
@import "~bulma/sass/elements/title";
@import "~bulma/sass/form/_all";
@import "~bulma/sass/components/navbar";
@import "~bulma/sass/layout/hero";
@import "~bulma/sass/layout/section";

// Set variables to overwrite Buefy
$family-sans-serif: 'Arial', sans-serif;

// Set your colors
$deep-sapphire: #012169;
$lochmara: #0085CA;
$calypso: #34657F;
$silver-chalice: #A7A8AA;

$primary: $deep-sapphire;
$primary-invert: findColorInvert($primary);
$info: $lochmara;
$info-invert: findColorInvert($info);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert)
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@import "~bulma";
@import "~buefy/src/scss/buefy";

* {
  // font-family: $family-sans-serif;
  font-family: CircularStd;
}

h1,h2,h3,h4,h5,h6,.title {
  // font-family: "Nexa", sans-serif;
  // font-weight: 700;
  font-family: CircularStd;
  font-weight: 700;
}

a:hover, a:active, a:focus {
  outline: 0;
}
.navbar-item::-moz-focus-inner {
  border: 0;
}
.has-light-border-top {
  border-top: 1px solid $grey-lighter;
}
.table {
  thead {
    td, th {
      &, & > * {
        // font-family: "Nexa", sans-serif;
        font-family: CircularStd;
      }
    }
  }
  &.is-unbordered {
    td, th {
      border-width: 0;
    }
  }
  &.is-aligned-middle {
    td, th {
      vertical-align: middle;
    }
  }
}
.outdated {
  margin-top: 1em;
  margin-bottom: 1em;
  border: 2px solid #ffb300;
  & > div {
    padding: 1em;
  }
}
</style>
