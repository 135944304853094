import axios from 'axios'

export default class BankID {
  constructor () {
    const client = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL + '/bankid/',
      timeout: 3000,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    this.client = client
  }

  /**
   * Initiate an auth request.
   *
   * Params:
   *   request = {
   *     personalNumber string // Optional
   *     autoStart      bool   // Required (Is the intention to autostart?)
   *     isMobile       bool   // Required (Is the requestor using a mobile device?)
   *     sameDevice     bool   // Required (Is the intention to auth on the same device?)
   *   }
   */
  auth (request) {
    return this.client.post('/auth', request)
  }

  /**
   * Initiate a sign request.
   *
   * Params:
   *  request = {
   *    personalNumber string
   *    userVisibleData string
   *  }
   */
  sign (request) {
    return this.client.post('/sign', request)
  }

  /**
   * Collect current state of auth/sign request.
   * Requirement: Must not be called more than once a second.
   *
   * Params:
   *   request = {
   *     orderRef               string // Required
   *     autoStart              bool   // Required (Is the intention to autostart?)
   *     isMobile               bool   // Required (Is the requestor using a mobile device?)
   *     sameDevice             bool   // Required (Is the intention to auth on the same device?)
   *     providedPersonalNumber bool   // Required (Was personalNumber provided to the auth?)
   *   }
   */
  collect (request) {
    return this.client.post('/collect', request)
  }
}
