import Vue from 'vue'
import Router from 'vue-router'
import ElectionSelection from './views/ElectionSelection.vue'
// import store from './store'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'election-selection',
      component: ElectionSelection
    },
    {
      path: '/election/:id',
      name: 'election',
      component: () => import(/* webpackChunkName: "loggedin" */ './views/Election.vue')
    },
    {
      path: '/om-oss',
      name: 'om-oss',
      component: () => import(/* webpackChunkName: "loggedin" */ './views/OmOss.vue')
    },
    {
      path: '/sparande',
      name: 'sparande',
      component: () => import(/* webpackChunkName: "loggedin" */ './views/Sparande.vue')
    },
    {
      path: '/pensionables',
      name: 'pensionables',
      component: () => import(/* webpackChunkName: "loggedin" */ './views/Pensionables.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "auth" */ './views/Login.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "auth" */ './views/Logout.vue')
    }
  ]
})
