<template>
  <div class="user-picker">
    <label class="has-text-weight-bold is-uppercase is-size-7" for="selector">Användare</label>
    <model-select id="selector"
                  :options="options"
                  :selected-options="[]"
                  v-model="value"
                  placeholder="Välj användare" />
    <!-- <button @click.prevent="doAction" class="button is-uppercase is-size-7">Visa</button> -->
  </div>
</template>

<script>
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'

export default {
  name: 'ActAsUserPicker',
  components: {
    ModelSelect
  },
  data () {
    return {
      value: {}
    }
  },
  computed: {
    users () {
      return this.$store.state.profile.raw.beneficiaries || []
    },
    delegates () {
      return this.$store.state.profile.raw.delegates || []
    },
    options () {
      return this.users.reduce((arr, user) => {
        arr.push({
          value: user.personalNumber,
          text: `${user.name} (${user.personalNumber})`
        })
        return arr
      },
      this.delegates.reduce((arr, user) => {
        arr.push({
          value: user.personalNumber,
          text: `${user.delegateName} (${user.personalNumber})`
        })
        return arr
      }, []))
    }
  },
  methods: {
    doAction () {
      const beneficiary = this.users.find(user => user.personalNumber === this.value.value)
      const delegate = this.delegates.find(delegate => delegate.personalNumber === this.value.value)
      this.$store.dispatch('actAs', {
        adminUser: null,
        delegate: delegate || null,
        beneficiary: beneficiary || null,
        beneficiaries: delegate ? this.users.filter(user => user.delegateId === delegate.delegateId) : null
      })
    }
  },
  watch: {
    value () {
      this.doAction()
    }
  }
}
</script>

<style scoped lang="scss">
$deep-sapphire: #012169;
$deep-sapphire-hover: #002783;

.user-picker {
  width: 16em;
  margin-left: auto;
}
.user-picker label {
  letter-spacing: .05em;
}
.button {
  letter-spacing: .05em;

  background-color: $deep-sapphire;
  border-color: transparent;
  box-shadow: none;

  align-items: center;
  border: 1px solid transparent;
  border-radius: 0;
  height: 2.25em;
  line-height: 1.5;
  position: relative;
  vertical-align: top;

  margin-top: 0.75em;

  font-weight: 500;
  color: #fff;
}
.button:hover {
  color: #fff;
  background-color: $deep-sapphire-hover;
}
.button:focus, .button:active {
  color: #fff;
}
</style>
