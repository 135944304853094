import axios from 'axios'

export default class ElectionAPI {
  constructor (accessToken) {
    const client = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL + '/api',
      timeout: 3000,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    client.defaults.headers.common.Authorization = 'Bearer ' + accessToken
    this.client = client
  }

  _getUrlParams (search = '') {
    const hashes = search.slice(search.indexOf('?') + 1).split('&')
    return hashes.reduce((acc, hash) => {
      const [key, val] = hash.split('=')
      return {
        ...acc,
        [key]: decodeURIComponent(val)
      }
    }, {})
  }

  /**
   * Get elections the user has access to
   */
  getElections () {
    return this.client.get('/elections')
  }

  castElectionVote (electionId, organizationId, data) {
    if (organizationId !== null) {
      return this.client.post(`/election/cast-for-org/${organizationId}/${electionId}`, data)
    }
    return this.client.post('/election/cast/' + electionId, data)
  }
}
