<template>
  <div class="home">
    <LoginRequired />
    <section class="section is-medium has-light-border-top" v-if="error">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-7">
            <b-message title="Ett fel uppstod" type="is-danger" aria-close-label="Stäng meddelande">
              Ett fel uppstod vid hämtning av uppgifter, testa igen om ett par minuter. Var god kontakta Stifta om problemet kvarstår
            </b-message>
          </div>
        </div>
      </div>
    </section>
    <section :class="'section ' + ($route.query.tid ? 'is-small' : 'is-medium has-light-border-top')" v-if="organizations && organizations.length > 0">
      <div class="container">
        <div class="columns">
          <div class="column is-full has-text-centered">
            <h1 class="title is-size-3 has-text-black">
              Hej, {{ userNiceName }}!
            </h1>
            <p class="subtitle has-text-black is-size-6" v-if="!$route.query.tid">
              Här är organisationer som du är behörig att rösta för
            </p>
            <p class="subtitle has-text-black is-size-6" v-if="!$route.query.tid">
              Arbetsgivare har rätt att rösta på två kandidater.
              <template v-if="electionsPrivate && electionsPrivate.length > 0">
                <br>Arbetstagare har rätt att rösta på två kandidater
              </template>
            </p>
          </div>
        </div>
      </div>
      <div v-if="$route.query.tid">
        <div class="columns is-centered">
          <div class="column is-6-desktop is-7-tablet has-text-centered">
            <p class="control">
              <router-link to="/?tid=" class="button is-primary">
                <span class="icon is-small">
                  <font-awesome-icon :icon="['far', 'undo']" :aria-hidden="true"></font-awesome-icon>
                </span>
                <span class="is-size-7">
                  Tillbaka / Välj annan organisation
                </span>
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div class="columns is-centered">
          <div class="column is-6-desktop is-7-tablet has-text-centered">
            <table class="table elections-table is-striped is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th class="is-size-6 has-text-weight-medium">Organisation</th>
                  <th class="is-size-6 has-text-weight-medium"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="org in organizations" :key="org.ID">
                  <td>
                    <div class="has-text-black">
                      {{ org.Name }}
                    </div>
                  </td>
                  <td>
                    <p class="control">
                      <router-link
                        class="button is-primary"
                        :to="'/?tid=org-' + org.ID">
                        <span class="icon is-small">
                          <font-awesome-icon :icon="['far', 'person-booth']" :aria-hidden="true"></font-awesome-icon>
                        </span>
                        <span class="is-size-7">
                          Agera som Arbetsgivare
                        </span>
                      </router-link>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="control" v-if="electionsPrivate && electionsPrivate.length > 0">
              Du kan också rösta som dig själv. Isåfall kan du:<br>
              <router-link to="/?tid=private" class="button is-primary">
                <span class="icon is-small">
                  <font-awesome-icon :icon="['far', 'person-booth']" :aria-hidden="true"></font-awesome-icon>
                </span>
                <span class="is-size-7">
                  Agera som Arbetstagare
                </span>
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section :class="'section ' + (organizations && organizations.length > 0 ? ' is-small' : 'is-medium has-light-border-top')" v-if="electionTable">
      <div class="container">
        <div class="columns">
          <div class="column is-full has-text-centered">
            <h1 class="title is-size-3 has-text-black">
              {{ electionTable.title }}
            </h1>
            <p class="subtitle has-text-black is-size-6">
              {{ electionTable.subtitle }}
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6-desktop is-7-tablet has-text-centered">
            <table class="table elections-table is-striped is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th class="is-size-6 has-text-weight-medium">Val</th>
                  <th class="is-size-6 has-text-weight-medium"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="election in electionTable.elections" :key="election.id">
                  <td>
                    <div class="has-text-black">
                      {{ election.Name }}
                    </div>
                  </td>
                  <td>
                    <p class="control">
                      <span v-if="!election.IsNominating && !election.IsVoting" class="is-size-7">
                        <font-awesome-icon :icon="['far', 'clock']" :aria-hidden="true"></font-awesome-icon>
                        <span class="ml-1">{{ election.StatusTextWaiting }}</span>
                      </span>
                      <router-link v-else-if="(election.IsNominating && !election.HasNominated) || (election.IsVoting && !election.HasVoted)"
                        class="button is-primary"
                        :to="electionLink(election)">
                        <span class="icon is-small">
                          <font-awesome-icon :icon="['far', 'ballot']" :aria-hidden="true"></font-awesome-icon>
                        </span>
                        <span class="is-size-7">
                          {{ election.IsNominating ? 'Nominera' : 'Rösta' }}
                        </span>
                        <span class="icon is-small">
                          <font-awesome-icon :icon="['far', 'arrow-circle-right']" :aria-hidden="true"></font-awesome-icon>
                        </span>
                      </router-link>
                      <span v-else class="is-size-7">
                        <font-awesome-icon :icon="['far', 'check']" :aria-hidden="true"></font-awesome-icon>
                        <span class="ml-1">{{ election.IsNominating ? 'Du har redan nominerat kandidater för detta val' : 'Du har redan röstat i detta val' }}</span>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'

import LoginRequired from '@/components/LoginRequired'

export default Vue.extend({
  name: 'home',
  components: {
    LoginRequired
  },
  data () {
    return {
    }
  },
  prototype: {
    moment
  },
  methods: {
    electionLink (election) {
      const queryParams = []
      if (election.Organization) {
        queryParams.push(`org=${election.Organization.ID}`)
      }
      return `election/${election.ID}?${queryParams.join('&')}`
    }
  },
  watch: {
  },
  computed: {
    account () {
      return (this.$store.getters.session != null) ? this.$store.getters.session.account : null
    },
    electionTable () {
      const tables = []

      for (const elections of Object.values(this.electionsPerOrg)) {
        tables.push({
          id: 'org-' + elections[0].Organization.ID,
          title: 'För ' + elections[0].Organization.Name + ' (' + elections[0].Organization.OrganizationNumber + ')',
          subtitle: 'Här är pågående val som du har rätt att delta i för ovan organisation',
          elections: elections
        })
      }

      if (this.electionsPrivate.length > 0) {
        tables.push({
          id: 'private',
          title: tables.length === 0 ? 'Hej, ' + this.userNiceName : 'För dig som Arbetstagare',
          subtitle: 'Här är pågående val som du har rätt att delta i',
          elections: this.electionsPrivate
        })
      }

      const tid = this.$route.query.tid
      if (!tid && tables.length === 1 && tables[0].id === 'private') {
        return tables[0]
      }
      return tables.find(table => table.id === tid)
    },
    electionsPrivate () {
      return _.cloneDeep(this.elections).filter(election => election.Organization === null)
    },
    organizations () {
      return _.cloneDeep(this.elections)
        .filter(election => election.Organization !== null)
        .map(election => election.Organization)
        .reduce((orgs, org) => {
          if (!orgs.find(o => o.ID === org.ID)) {
            orgs.push(org)
          }
          return orgs
        }, [])
    },
    electionsPerOrg () {
      const perOrg = {}
      _.cloneDeep(this.elections).forEach(election => {
        if (election.Organization) {
          if (!perOrg[election.Organization.ID]) {
            perOrg[election.Organization.ID] = []
          }
          perOrg[election.Organization.ID].push(election)
        }
      })
      return perOrg
    },
    elections () {
      return this.$store.state.election.elections && this.$store.state.election.elections.Elections ? [...this.$store.state.election.elections.Elections] : []
    },
    error () {
      return this.$store.state.election.error
    },
    userNiceName () {
      let niceName = (this.account != null) ? this.account.name : null
      if (niceName != null) {
        niceName = niceName.toLowerCase()
        let split = niceName.split(' ')
        for (let i = 0; i < split.length; i++) {
          split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1)
        }
        niceName = split.join(' ')

        split = niceName.split('-')
        for (let i = 0; i < split.length; i++) {
          split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1)
        }
        niceName = split.join('-')
      }

      return niceName
    }
  }
})
</script>

<style scoped lang="scss">
  $deep-sapphire: #012169;
  $lochmara: #0085CA;
  $calypso: #34657F;
  $silver-chalice: #A7A8AA;
  $black: #2a2c32;
  $gray: #737373;
  $white: #F5F5F5;
  $light-gray: #efefef;

  .capital-change {
    position: absolute;
    margin: 0.75em 0 0 1em;
    padding: 0.25em 0.75em;
    color: $gray;
    font-size: 0.6rem;
  }
  .capital-change.green {
    background: #E9FAE5
  }

  .capital-change.red {
    background: #FAE5E5
  }

  .capital-dash {
    color: $silver-chalice;
    position: relative;
    top: 1px;
  }

  .has-text-gray {
    color: $gray;
  }

  .button.payout {
    border-radius: 0;
    background: $black;
    color: $white;
    margin-top: 1em;
  }

  .profile-image {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
  }

  .hero-body {
    background: $deep-sapphire;
    .container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 37px;
      line-height: 45px;
    }
    .subtitle {
      font-size: 14px;
    }
  }
  .is-justified-start {
    justify-content: flex-start;
  }
  .is-aligned-start {
    align-items: flex-start;
  }
  .is-half {
    width: 50%;
  }
  .capital-header {
    color: $silver-chalice
  }
  .border-box {
    // display: flex;
    border: $silver-chalice 1px solid;
    padding: 5rem 0rem;
    flex-basis: 50%;
  }
  // .border-box:first-child {
  //   border-right: 0px
  // }
  .level-item {
    &.is-level-column {
      flex-direction: column;
      flex-shrink: 1;
    }
  }
  .footnote {
    padding: 0.25rem;
    transition: background 200ms linear, color 200ms linear;
  }
  .footnote:target {
    background: rgba($deep-sapphire, 0.07);
    border-radius: 2px;
    color: $deep-sapphire;
  }

  .notimplemented {
    background-color: yellow;
    color: red;
  }

  .control .button.is-primary {
    background-color: $deep-sapphire;
  }

  .elections-table {
    border-collapse: collapse;
    margin-bottom: 1rem;
    padding: 0;
    border: 2px solid $light-gray;
  }

  .elections-table th {
    text-align: left;
    padding: 0.25rem 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.5rem;
    border: none;
    border-bottom: 1px solid $light-gray;
  }

  .elections-table td {
    text-align: left;
    padding: 0.25rem 1rem;
    vertical-align: middle;
    border: none;
  }

  .elections-table td:last-of-type {
    text-align: right;
  }
</style>
